input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #546690;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #546690;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #546690;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #546690;
}
#modalss .nav-tabs > li a {
  background: #ccc;
  color: #fff;
  border: none;
}
/* #modalss .nav-tabs>li{
background: #c52d2f;color: #fff;border: none;
}
*/
#modalss .nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: red;
  color: #fff;
}
#modalss .tab-content {
  margin-top: 10px;
}
#modalss p {
  font-size: 16px;
  color: #fff;
  line-height: 36px;
}
#modalss p a {
  color: #fff;
}
#modalss span {
  background-color: #1f5dbe;
  border-radius: 50%;
  padding: 4px 10px;
  margin: 10px;
}

:root{
  font-size: 16px;
}
.alert-box-wrapper {
  p,
  button {
    font-size: 16px !important;
  }
}
