@charset "UTF-8";
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,300,800");
iframe#agreement-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  border: none;
}

iframe#iframe-tutorial {
  width: 100%;
  height: 600px;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #546690;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #546690;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #546690;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #546690;
}

#modalss .nav-tabs > li a {
  background: #ccc;
  color: #fff;
  border: none;
}

/* #modalss .nav-tabs>li{
background: #c52d2f;color: #fff;border: none;
}
*/
#modalss .nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: red;
  color: #fff;
}

#modalss .tab-content {
  margin-top: 10px;
}

#modalss p {
  font-size: 16px;
  color: #fff;
  line-height: 36px;
}

#modalss p a {
  color: #fff;
}

#modalss span {
  background-color: #1f5dbe;
  border-radius: 50%;
  padding: 4px 10px;
  margin: 10px;
}

:root {
  font-size: 16px;
}

.alert-box-wrapper p,
.alert-box-wrapper button {
  font-size: 16px !important;
}

.sidebar {
  position: fixed;
  top: 50%;
  right: 1rem;
  width: 50px;
  z-index: 50;
  transform: translateY(-50%);
}
.sidebar .sidebar-item {
  display: block;
  background: linear-gradient(to right, #1d2344, #0053ff);
  font-size: 50px;
  border-radius: 50%;
  width: 1em;
  min-width: 1em;
  height: 1em;
  min-height: 1em;
  position: relative;
  transition: all 200ms ease-in-out;
}
.sidebar .sidebar-item:not(:last-child) {
  margin-bottom: 5px;
}
.sidebar .sidebar-item:hover .sidebar-desc {
  transform: translate(0, -50%);
  opacity: 1;
  visibility: visible;
}
.sidebar .sidebar-item:hover .sidebar-desc.appqr-wrapper {
  transform: translate(0);
}
@supports (filter: brightness(1.2)) {
  .sidebar .sidebar-item:hover:hover {
    filter: brightness(1.2);
  }
}
.sidebar .sidebar-item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 88%;
  height: 88%;
  margin: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.sidebar .sidebar-item.navigator {
  background-position-y: 0;
}
.sidebar .sidebar-item.navigator:before {
  background-image: url("../img/icon-daohang.svg");
}
.sidebar .sidebar-item.app {
  background-position-y: -1em;
}
.sidebar .sidebar-item.app:before {
  background-image: url("../img/icon-app.svg");
}
.sidebar .sidebar-item.qq {
  background-position-y: -2em;
}
.sidebar .sidebar-item.qq:before {
  background-image: url("../img/qq.svg");
}
.sidebar .sidebar-item.wechat {
  background-position-y: -3em;
}
.sidebar .sidebar-item.wechat:before {
  background-image: url("../img/wechat.svg");
}
.sidebar .sidebar-item.customer-service {
  background-position-y: -4em;
}
.sidebar .sidebar-item.customer-service:before {
  background-image: url("../img/customer-service.svg");
}
.sidebar .sidebar-item.customer-service-app:before {
  background-image: url("../img/icon-kefuapp.svg");
  background-size: 60%;
}
.sidebar .sidebar-item.livechat-service {
  background-position-y: -5em;
}
.sidebar .sidebar-item.livechat-service:before {
  background-image: url("../img/icon-livechat.svg");
}
.sidebar .sidebar-item.macos-download {
  background-position-y: -6em;
}
.sidebar .sidebar-item.macos-download:before {
  background-image: url("../img/macos.svg");
}
.sidebar .sidebar-item.windows-download {
  background-position-y: -7em;
}
.sidebar .sidebar-item.windows-download:before {
  background-image: url("../img/windows.svg");
}
.sidebar .sidebar-item.morse:before {
  background-image: url("../img/icon-morse-white.svg");
}
.sidebar .sidebar-item.back-to-top {
  background: #0067ac;
  opacity: 0;
}
.sidebar .sidebar-item.back-to-top:before {
  background-image: url("../img/up-arrow.svg");
  background-size: 50% 50%;
}
.sidebar .sidebar-item .sidebar-desc {
  display: block;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  text-decoration: none;
  position: absolute;
  top: 50%;
  transform: translate(-30%, -50%);
  right: 60px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.8125rem;
  color: #fff;
  transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sidebar .sidebar-item .sidebar-desc:not(.appqr-wrapper) {
  white-space: nowrap;
}
@supports (-webkit-backdrop-filter: blur(10px)) {
  .sidebar .sidebar-item .sidebar-desc {
    -webkit-backdrop-filter: blur(10px);
  }
}
.sidebar .sidebar-item .sidebar-desc canvas {
  width: 200px;
  height: 200px;
}
.sidebar .sidebar-item .sidebar-desc:before {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper {
  top: 0;
  transform: translate(-50%, 0);
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper:before {
  content: "";
  position: absolute;
  top: 10%;
  right: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.7);
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper .appqrcode {
  width: 150px;
  background-color: #fff;
  border-radius: 4px 4px 0 0;
  padding: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper .appqrcode img {
  display: none;
  width: 130px;
  height: 130px;
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper .appqrcode canvas {
  width: 130px !important;
  height: 130px !important;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper .appqrcode .text {
  color: #000;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-style: italic;
  margin-top: 7px;
}
.sidebar .sidebar-item .sidebar-desc.appqr-wrapper .appdlurl {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  color: #fff;
  border-radius: 0 0 4px 4px;
  padding: 5px 3px;
}

.left-floater {
  position: fixed;
  top: 40%;
  left: 0;
  z-index: 50;
  transform: translateY(-50%);
  width: 150px;
  height: 250px;
  background: linear-gradient(to bottom, #02142e, #015e74);
}
.left-floater .qr-wrapper {
  position: absolute;
  height: auto;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  padding: 0.5rem;
  background-color: #000;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
}
.left-floater .qr-wrapper .qr-code {
  margin-bottom: 8px;
}
.left-floater .qr-wrapper .qr-code canvas {
  display: block;
  margin: auto;
  position: relative;
  width: 100% !important;
  height: 100% !important;
}
.left-floater .qr-wrapper .text {
  font-size: 20px;
  margin: 0 0 0.25rem;
  font-style: italic;
  font-weight: bold;
}
/*************************
*******Typography******
**************************/
body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  color: #4e4e4e;
  line-height: 22px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}

body.ready .body-wrapper {
  opacity: 1;
}

h1,
h2,
h3 {
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #4e4e4e;
}

h1 {
  font-size: 36px;
  color: #fff;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 28px;
  color: #787878;
  font-weight: 400;
  line-height: 24px;
}

h4 {
  font-size: 16px;
}

a {
  color: #c52d2f;
  -webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition: color 300ms, background-color 300ms;
  transition: color 300ms, background-color 300ms;
}
a:hover, a:focus {
  color: #d43133;
}

hr {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #fff;
}

ul,
li {
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}

.flex {
  display: flex;
  align-items: center;
}

.btn-primary {
  padding: 8px 20px;
  background: #c52d2f;
  color: #fff;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
}
.btn-primary:hover, .btn-primary:focus {
  background: #c52d2f;
  outline: none;
  box-shadow: none;
}

.btn-transparent {
  border: 3px solid #fff;
  background: transparent;
  color: #fff;
}
.btn-transparent:hover {
  border-color: rgba(255, 255, 255, 0.5);
}

a:hover, a:focus {
  color: #111;
  text-decoration: none;
  outline: none;
}

.dropdown-menu {
  margin-top: -1px;
  min-width: 180px;
}

.center h2 {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
}

.media > .pull-left {
  margin-right: 20px;
}
.media > .pull-right {
  margin-left: 20px;
}

body .body-wrapper > section {
  padding: 70px 0;
}

.center {
  text-align: center;
  padding-bottom: 55px;
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

.lead {
  font-size: 16px;
  line-height: 24px;
}

.transparent-bg {
  background-color: transparent !important;
  margin-bottom: 0;
}

@-webkit-keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}
.shu-g {
  display: inline-block;
  width: 2px;
  height: 12px;
  background: #fff;
}

/*************************
*******Header******
**************************/
#header .search {
  display: inline-block;
}

#header nav {
  border-radius: 0;
}

.navbar > .container .navbar-brand {
  margin-left: 0;
}

.top-bar {
  padding: 10px 0;
  background: #1d2344;
  border-bottom: 1px solid #373c58;
  line-height: 28px;
}

.top-number {
  color: #fff;
}
.top-number p {
  margin: 0;
}

.social {
  text-align: right;
}

.social-share {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

ul.social-share li {
  display: inline-block;
}
ul.social-share li a {
  display: inline-block;
  color: #fff;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 2px;
}
ul.social-share li a:hover {
  /*background: #c52d2f;*/
  color: #c52d2f;
}

.navbar-brand {
  padding: 0;
  margin-top: 6px;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  background: #1d2344;
  padding-bottom: 0;
}

.navbar-inverse {
  border: none;
}
.navbar-inverse .navbar-nav > li > a {
  padding: 20px 23px;
  margin: 0;
  line-height: 24px;
  display: inline-block;
  border-radius: 0;
  font-size: 18px;
  color: #fff;
}
.navbar-inverse .navbar-nav > li > a:hover {
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
.navbar-inverse .navbar-brand {
  font-size: 36px;
  line-height: 50px;
  color: #fff;
}

/*.navbar-inverse .navbar-nav > .active > a:focus{
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
*/
.hed .navbar-inverse .navbar-nav > .active > a {
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
.hed .navbar-inverse .navbar-nav > .active > a:hover, .hed .navbar-inverse .navbar-nav > .active > a:focus {
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
.hed .navbar-inverse .navbar-nav > a {
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
.hed .navbar-inverse .navbar-nav > a:hover, .hed .navbar-inverse .navbar-nav > a:focus {
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}

/*.navbar-inverse .navbar-nav .dropdown-menu {
  background-color: rgba(0,0,0,.85);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  border: 0;
  padding: 0;
  margin-top: 0;
  border-top: 0;
  border-radius: 0;
  left: 0;
}
*/
/*.navbar-inverse .navbar-nav .dropdown-menu:before{
  position: absolute;
  top:0;
}

.navbar-inverse .navbar-nav .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #fff;
}
*/
/*.navbar-inverse .navbar-nav .dropdown-menu > li:hover > a,
.navbar-inverse .navbar-nav .dropdown-menu > li:focus > a,
.navbar-inverse .navbar-nav .dropdown-menu > li.active > a {
  background-color: #c52d2f;
  color: #fff;
}*/
.navbar-inverse .navbar-nav .dropdown-menu > li:last-child > a {
  border-radius: 0 0 3px 3px;
}
.navbar-inverse .navbar-nav .dropdown-menu > li.divider {
  background-color: transparent;
}
.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-top: 0;
  padding-bottom: 0;
}

/*************************
*******Home Page******
**************************/
#main-slider {
  position: relative;
  padding: 0;
  /*background: #0c1127;*/
}
#main-slider > .carousel {
  width: 100%;
}
#main-slider > .carousel > .carousel-inner {
  width: 100% !important;
}

.no-margin {
  margin: 0;
  padding: 0;
}

#main-slider .carousel .carousel-content {
  margin-top: 150px;
}
#main-slider .carousel .slide-margin {
  margin-top: 140px;
}
#main-slider .carousel h2 {
  color: #fff;
}
#main-slider .carousel .btn-slide {
  padding: 8px 20px;
  background: #c52d2f;
  color: #fff;
  border-radius: 4px;
  margin-top: 25px;
  display: inline-block;
}
#main-slider .carousel .slider-img {
  text-align: right;
  position: absolute;
}
#main-slider .carousel .item {
  background-position: 50%;
  background-repeat: no-repeat;
  /*background-size: cover;*/
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block !important;
  height: 383px;
  -webkit-transition: opacity ease-in-out 500ms;
  -moz-transition: opacity ease-in-out 500ms;
  -o-transition: opacity ease-in-out 500ms;
  transition: opacity ease-in-out 500ms;
}
#main-slider .carousel .item:first-child {
  top: auto;
  position: relative;
}
#main-slider .carousel .item.active {
  opacity: 1;
  -webkit-transition: opacity ease-in-out 500ms;
  -moz-transition: opacity ease-in-out 500ms;
  -o-transition: opacity ease-in-out 500ms;
  transition: opacity ease-in-out 500ms;
  z-index: 1;
}
#main-slider .prev,
#main-slider .next {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  /*
  background-color: #c52d2f;*/
  color: #fff;
  margin-top: -25px;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
  z-index: 5;
}
#main-slider .prev:hover,
#main-slider .next:hover {
  background-color: #000;
}
#main-slider .prev {
  left: 10px;
}
#main-slider .next {
  right: 10px;
}
#main-slider .carousel-indicators {
  width: 48%;
}
#main-slider .carousel-indicators li {
  width: 20px;
  height: 20px;
  background-color: transparent;
  margin: 0 15px 0 0;
  position: relative;
}
#main-slider .carousel-indicators li:after {
  position: absolute;
  content: "";
  width: 38px;
  height: 20px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);
  left: -3px;
  top: -3px;
}
#main-slider .carousel-indicators .active {
  width: 38px;
  height: 20px;
  background-color: #c52d2f;
  margin: 0 15px 0 0;
  /*border: 1px solid #c52d2f;*/
  position: relative;
}
#main-slider .carousel-indicators .active:after {
  position: absolute;
  content: "";
  width: 38px;
  height: 20px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);
  /*border: 1px solid #FFF;*/
  left: -3px;
  top: -3px;
}
#main-slider .active .animation.animated-item-1 {
  -webkit-animation: fadeInUp 300ms linear 300ms both;
  -moz-animation: fadeInUp 300ms linear 300ms both;
  -o-animation: fadeInUp 300ms linear 300ms both;
  -ms-animation: fadeInUp 300ms linear 300ms both;
  animation: fadeInUp 300ms linear 300ms both;
}
#main-slider .active .animation.animated-item-2 {
  -webkit-animation: fadeInUp 300ms linear 600ms both;
  -moz-animation: fadeInUp 300ms linear 600ms both;
  -o-animation: fadeInUp 300ms linear 600ms both;
  -ms-animation: fadeInUp 300ms linear 600ms both;
  animation: fadeInUp 300ms linear 600ms both;
}
#main-slider .active .animation.animated-item-3 {
  -webkit-animation: fadeInUp 300ms linear 900ms both;
  -moz-animation: fadeInUp 300ms linear 900ms both;
  -o-animation: fadeInUp 300ms linear 900ms both;
  -ms-animation: fadeInUp 300ms linear 900ms both;
  animation: fadeInUp 300ms linear 900ms both;
}
#main-slider .active .animation.animated-item-4 {
  -webkit-animation: fadeInUp 300ms linear 1200ms both;
  -moz-animation: fadeInUp 300ms linear 1200ms both;
  -o-animation: fadeInUp 300ms linear 1200ms both;
  -ms-animation: fadeInUp 300ms linear 1200ms both;
  animation: fadeInUp 300ms linear 1200ms both;
}

/*##############################################################*/
#main-sliders {
  position: relative;
  /*background: #0c1127;*/
}

.no-margin {
  margin: 0;
  padding: 0;
}

#main-sliders .carousel-content {
  margin-top: 150px;
}
#main-sliders .slide-margin {
  margin-top: 140px;
}
#main-sliders h2 {
  color: #fff;
}
#main-sliders .btn-slide {
  padding: 8px 20px;
  background: #c52d2f;
  color: #fff;
  border-radius: 4px;
  margin-top: 25px;
  display: inline-block;
}
#main-sliders .slider-img {
  text-align: right;
  position: absolute;
}
#main-sliders .sd {
  background-image: url(../img/cai1.png);
  height: 135px;
  width: 240px;
  margin: 20px;
  border-radius: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  box-shadow: #ccc 0px 0px 5px;
}
#main-sliders .item {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block !important;
  -webkit-transition: opacity ease-in-out 500ms;
  -moz-transition: opacity ease-in-out 500ms;
  -o-transition: opacity ease-in-out 500ms;
  transition: opacity ease-in-out 500ms;
}
#main-sliders .item:first-child {
  top: auto;
  position: relative;
}
#main-sliders .item.active {
  opacity: 1;
  -webkit-transition: opacity ease-in-out 500ms;
  -moz-transition: opacity ease-in-out 500ms;
  -o-transition: opacity ease-in-out 500ms;
  transition: opacity ease-in-out 500ms;
  z-index: 1;
}
#main-sliders .prev,
#main-sliders .next {
  position: absolute;
  top: 50%;
  background-color: #c52d2f;
  color: #fff;
  margin-top: -25px;
  height: 35px;
  width: 35px;
  text-align: center;
  border-radius: 4px;
  z-index: 5;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
#main-sliders .prev:hover,
#main-sliders .next:hover {
  background-color: #ccc;
}
#main-sliders .prev {
  left: 10px;
}
#main-sliders .next {
  right: 10px;
}
#main-sliders .carousel-indicators li {
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin: 0 15px 0 0;
  position: relative;
}
#main-sliders .carousel-indicators li:after {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  left: -3px;
  top: -3px;
}
#main-sliders .carousel-indicators .active {
  width: 20px;
  height: 20px;
  background-color: #c52d2f;
  margin: 0 15px 0 0;
  border: 1px solid #c52d2f;
  position: relative;
}
#main-sliders .carousel-indicators .active:after {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: #c52d2f;
  border: 1px solid #c52d2f;
  left: -3px;
  top: -3px;
}
#main-sliders .active .animation.animated-item-1 {
  -webkit-animation: fadeInUp 300ms linear 300ms both;
  -moz-animation: fadeInUp 300ms linear 300ms both;
  -o-animation: fadeInUp 300ms linear 300ms both;
  -ms-animation: fadeInUp 300ms linear 300ms both;
  animation: fadeInUp 300ms linear 300ms both;
}
#main-sliders .active .animation.animated-item-2 {
  -webkit-animation: fadeInUp 300ms linear 600ms both;
  -moz-animation: fadeInUp 300ms linear 600ms both;
  -o-animation: fadeInUp 300ms linear 600ms both;
  -ms-animation: fadeInUp 300ms linear 600ms both;
  animation: fadeInUp 300ms linear 600ms both;
}
#main-sliders .active .animation.animated-item-3 {
  -webkit-animation: fadeInUp 300ms linear 900ms both;
  -moz-animation: fadeInUp 300ms linear 900ms both;
  -o-animation: fadeInUp 300ms linear 900ms both;
  -ms-animation: fadeInUp 300ms linear 900ms both;
  animation: fadeInUp 300ms linear 900ms both;
}
#main-sliders .active .animation.animated-item-4 {
  -webkit-animation: fadeInUp 300ms linear 1200ms both;
  -moz-animation: fadeInUp 300ms linear 1200ms both;
  -o-animation: fadeInUp 300ms linear 1200ms both;
  -ms-animation: fadeInUp 300ms linear 1200ms both;
  animation: fadeInUp 300ms linear 1200ms both;
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
#services {
  background: #000 url(../../img/fwbanner.png);
  background-size: cover;
}

.services-wrap {
  padding: 44px 0px;
  background: #fff;
  border-radius: 4px;
}
.services-wrap h3 {
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;
}
.services-wrap .pull-left {
  margin-right: 20px;
}

#about-us {
  background: #000 url(../../img/gywmbanner.png);
  background-size: cover;
}
#about-us .bgcolor {
  background: #79809e;
  color: #fff;
}
#about-us .bgcolor:hover {
  background: #dc292f;
}
#about-us h3 {
  color: #fff;
  letter-spacing: 2px;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}

#about-us .lead {
  color: #fff;
  letter-spacing: 4px;
}

.text-font {
  height: 200px;
  padding: 0 40px 70px;
}

.text-fonts {
  padding: 26px 40px 20px;
}

#support-services h3 {
  color: #fff;
}
#support-services .lead {
  color: #fff;
  letter-spacing: 4px;
}

#game .lead {
  color: #ccc;
  letter-spacing: 4px;
  margin-bottom: -19px;
}

/***********************
****Service page css****
***********************/
.services {
  padding: 0;
}

.get-started {
  background: none repeat scroll 0 0 #f3f3f3;
  border-radius: 3px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 18px;
  margin-top: 60px;
}
.get-started h2 {
  padding-top: 30px;
  margin-bottom: 20px;
}

.request {
  bottom: -15px;
  left: 50%;
  position: absolute;
  margin-left: -110px;
}
.request h4 {
  position: absolute;
  width: 220px;
  position: relative;
}
.request h4 a {
  background: #c52d2f;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  border-radius: 3px;
  padding: 5px 15px;
}
.request h4:after {
  border-color: #c52d2f transparent transparent;
  border-style: solid;
  border-width: 12px;
  bottom: -18px;
  content: "";
  height: 0;
  position: absolute;
  right: 13px;
  z-index: -99999;
}
.request h4:before {
  border-color: #c52d2f transparent transparent;
  border-style: solid;
  border-width: 12px;
  bottom: -18px;
  content: "";
  height: 0;
  left: 13px;
  position: absolute;
  z-index: -99999;
}

.clients-area {
  padding: 60px;
}

.clients-comments p {
  font-size: 20px;
  font-family: "微软雅黑";
  line-height: 26px;
  margin: 35px;
}
.clients-comments i {
  color: red;
  font-size: 24px;
}
.clients-comments h4 {
  font-weight: 300;
  margin-top: 15px;
}
.clients-comments h4 span {
  font-weight: 700;
  font-style: oblique;
}

.footer {
  background: none repeat scroll 0 0 #2e2e2e;
  border-top: 5px solid #c52d2f;
  height: 84px;
  margin-top: 110px;
}

.footer_left {
  padding: 10px;
}

.text-left {
  color: #ffffff;
  font-size: 12px;
  margin-top: 15px;
}

/*********************
**********************
*****************
********************game*
***********************
*********************/
#game .game-le img {
  padding: 0;
}
#game .media-body {
  margin-top: 36px;
}
#game button {
  border-radius: 20px;
}

/***********************
********* Footer ******
************************/
#bottom {
  background: #f5f5f5;
  border-bottom: 5px solid #c52d2f;
  font-size: 14px;
}
#bottom h3 {
  margin-top: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 22px;
}
#bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#bottom ul li {
  display: block;
  padding: 5px 0;
}
#bottom ul li a {
  color: #808080;
}
#bottom ul li a:hover {
  color: #c52d2f;
}
#bottom .widget {
  margin-bottom: 0;
}

#footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  background: #b52a2b;
}
#footer a {
  color: #fff;
}
#footer a:hover {
  color: #c52d2f;
}
#footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer ul > li {
  display: inline-block;
  margin-left: 15px;
}

/*****************
about-us**********
******************/
/*support-services*/
#support-services {
  background: #1d2344;
}

/*banner 上登录*/
.fatt {
  position: absolute;
  z-index: 20;
  top: 7%;
  left: 67%;
}

.user_icon {
  position: relative;
  width: 200px;
  z-index: 40;
  margin-bottom: 10px;
  color: #26366e;
}
.user_icon i {
  position: absolute;
  z-index: 55;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

.login_txtbx {
  width: 200px;
  height: 34px;
  padding-left: 22px;
  background: #0f111d;
  border: 1px solid #2f4280;
  caret-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
}

.login_txtbxs {
  width: 110px;
  height: 34px;
  padding-left: 22px;
  background: #0f111d;
  border: 1px solid #2f4280;
  caret-color: #fff;
  color: #fff;
}

.pwd_icon {
  position: relative;
  width: 200px;
  z-index: 40;
  color: #26366e;
  margin-bottom: 10px;
}
.pwd_icon i {
  position: absolute;
  left: 0;
  z-index: 55;
  padding: 12px 5px;
}

.val_icon {
  display: inline-flex;
  position: relative;
  width: 215px;
  z-index: 40;
  color: #26366e;
  margin-bottom: 20px;
}
.val_icon i {
  position: absolute;
  left: 0;
  z-index: 55;
  padding: 12px 5px;
}
.val_icon canvas {
  width: 70px;
  height: 38px;
  margin-left: 16px;
  padding-left: 8px;
  background: #c1d2e0;
}

.land {
  width: 100px;
  border-radius: 20px;
  padding: 3px 12px;
}

/*container*/
/*Open account 页面*/
.login {
  background: #fff;
  height: 100%;
  padding: 10px 20px;
  height: 700px;
}
.login form {
  width: 380px;
  margin: auto;
  padding: 10px 0;
}

.logn_in {
  display: inline-flex;
  position: relative;
  /*justify-content: space-between;*/
  align-items: center;
  z-index: 40;
  width: 380px;
  height: 45px;
  padding-left: 22px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #fafafa;
}

.logn_ins {
  display: inline-flex;
  position: relative;
  /*justify-content: space-between;*/
  align-items: center;
  z-index: 40;
  width: 266px;
  height: 45px;
  padding-left: 22px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #fafafa;
}
.logn_ins + .J_codeimg {
  height: 45px;
  margin-bottom: 2px;
}

.logn_in i {
  position: absolute;
  left: 0;
  z-index: 55;
  padding: 14px 6px;
}

.J_codeimg {
  width: 80px;
  margin-left: 10px;
  padding-left: 8px;
  background: #ccc;
}

.logn_in input,
.logn_ins input {
  width: 100%;
  padding-left: 22px;
  border: none;
  background: #fafafa;
}

/*.logn_in .required{
    width: 380px;
    height: 45px;
    padding-left: 22px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
}*/
.logn_in .requireds {
  /*  width: 270px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;*/
}

.leads {
  color: #ccc;
  font-size: 14px;
  letter-spacing: 4px;
  margin-bottom: -19px;
  font-weight: 100;
}

.botn {
  width: 380px;
  height: 46px;
  font-size: 18px;
  margin-top: 20px;
}

/*deposit-withdrawal*/
.text_wen {
  font-size: 18px;
  color: black;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  font-weight: bold;
}

#scrollDiv {
  position: absolute;
  width: 55%;
  height: 36px;
  min-height: 20px;
  line-height: 24px;
  border: #ccc 1px solid;
  overflow: hidden;
  background: #fff;
  left: 22%;
  top: 97%;
  box-shadow: #666 0px 0px 10px;
  z-index: 70;
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
}
#scrollDiv .marquee-title {
  margin: 0 12px 0 0;
}
#scrollDiv > .marquee-wrapper {
  width: calc(100% - 120px);
  color: #000;
}

#scrollDiv li {
  width: 100%;
  height: 20px;
  padding: 20px;
  margin-left: 30px;
  color: black;
}

.title {
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: -webkit-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: -moz-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: -o-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
}

.titles {
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: -moz-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: -o-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
  background-image: linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgb(12, 17, 39) 100%);
}

.small_lun {
  width: 100%;
  left: 30%;
  background: rgba(95, 95, 95, 0.5);
  height: 45px;
  line-height: 47px;
  bottom: -10px;
}

#content {
  background: url(../img/bg.png);
  background-size: cover;
  /*background: no-repeat;*/
}

a {
  color: black;
}

.sub-content-nav .nav > li > a {
  padding: 10px 5px;
  box-sizing: border-box;
  background: transparent !important;
}

.nav-tabs > li > a {
  font-size: 16px;
}

.nav-tabs > li > a:hover {
  color: red;
  border: none;
  background: transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  cursor: default;
  border: none;
  color: red;
  border-bottom: 3px solid red;
}

.rights {
  background: #fff;
  padding: 10px 20px;
}

#content .media-heading {
  font-size: 18px;
}

#content .media-cont {
  color: #ccc;
}

#content .pull-right {
  margin-top: -41px;
  color: #ccc;
}

#content .media-body {
  margin-top: 10px;
}

#content .services-coj {
  padding: 20px;
  margin-bottom: 10px;
}

#content .tab-content {
  margin-top: 10px;
}

.panel-group .panel {
  border: none;
}

.panel-default > .panel-heading {
  background: transparent;
}

.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-bottom: 2px dashed #ccc;
  border-top: none;
}

.rule-content ul,
.rule-content ol {
  padding-left: 2rem;
}
.rule-content li {
  line-height: 2rem;
}
.rule-content .tab-pane a {
  color: #1a2c8e;
}
.rule-content .red {
  color: red;
}

.promo-content h1 {
  color: initial;
}
.promo-content .item {
  margin-bottom: 20px;
}
.promo-content .item-head {
  width: 100%;
}
.promo-content .item-content {
  display: none;
  background-color: #fff;
  box-sizing: border-box;
}

.app-download-popup {
  display: flex;
  justify-content: center;
  width: 800px !important;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 24px;
  box-sizing: border-box;
  margin: auto;
}
.app-download-popup .qr-code {
  position: absolute;
  top: 105px;
  right: 49px;
}

body .swipe-captcha__overlay {
  z-index: 99;
}