$sidebar-bg: "../img/bg_floater.jpg";
$navigator: "../img/icon-daohang.svg";
$app: "../img/icon-app.svg";
$wechat: "../img/wechat.svg";
$qq: "../img/qq.svg";
$customerService: "../img/customer-service.svg";
$csApp: "../img/icon-kefuapp.svg";
$livechatService: "../img/icon-livechat.svg";
$macOS: "../img/macos.svg";
$windows: "../img/windows.svg";
$morse: "../img/icon-morse-white.svg";
$backToTop: "../img/up-arrow.svg";

.sidebar {
  position: fixed;
  top: 50%;
  right: 1rem;
  width: 50px;
  z-index: 50;
  transform: translateY(-50%);
  .sidebar-item {
    display: block;
    background: linear-gradient(to right, #1d2344, #0053ff);
    font-size: 50px;
    border-radius: 50%;
    width: 1em;
    min-width: 1em;
    height: 1em;
    min-height: 1em;
    position: relative;
    transition: all 200ms ease-in-out;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &:hover {
      .sidebar-desc {
        transform: translate(0, -50%);
        opacity: 1;
        visibility: visible;
        &.appqr-wrapper {
          transform: translate(0);
        }
      }
      @supports (filter: brightness(1.2)) {
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 88%;
      height: 88%;
      margin: auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.navigator {
      background-position-y: 0;
      &:before {
        background-image: url($navigator);
      }
    }
    &.app {
      background-position-y: -1em;
      &:before {
        background-image: url($app);
      }
    }
    &.qq {
      background-position-y: -2em;
      &:before {
        background-image: url($qq);
      }
    }
    &.wechat {
      background-position-y: -3em;
      &:before {
        background-image: url($wechat);
      }
    }
    &.customer-service {
      background-position-y: -4em;
      &:before {
        background-image: url($customerService);
      }
    }
    &.customer-service-app {
      &:before {
        background-image: url($csApp);
        background-size: 60%;
      }
    }
    &.livechat-service {
      background-position-y: -5em;
      &:before {
        background-image: url($livechatService);
      }
    }
    &.macos-download {
      background-position-y: -6em;
      &:before {
        background-image: url($macOS);
      }
    }
    &.windows-download {
      background-position-y: -7em;
      &:before {
        background-image: url($windows);
      }
    }

    &.morse {
      &:before {
        background-image: url($morse);
      }
    }

    &.back-to-top {
      background: #0067ac;
      opacity: 0;
      &:before {
        background-image: url($backToTop);
        background-size: 50% 50%;
      }
    }

    .sidebar-desc {
      display: block;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-decoration: none;
      position: absolute;
      top: 50%;
      transform: translate(-30%, -50%);
      right: 60px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      padding: 5px;
      text-align: center;
      box-sizing: border-box;
      font-size: 0.8125rem;
      color: #fff;
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &:not(.appqr-wrapper) {
        white-space: nowrap;
      }
      @supports (-webkit-backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
      }
      canvas {
        width: 200px;
        height: 200px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(0, 0, 0, 0.7);
      }
      &.appqr-wrapper {
        top: 0;
        transform: translate(-50%, 0);
        &:before {
          content: "";
          position: absolute;
          top: 10%;
          right: -10px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid rgba(0, 0, 0, 0.7);
        }
        .appqrcode {
          width: 150px;
          background-color: #fff;
          border-radius: 4px 4px 0 0;
          padding: 10px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          img {
            display: none;
            width: 130px;
            height: 130px;
          }
          canvas {
            width: 130px !important;
            height: 130px !important;
            background-color: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }
          .text {
            color: #000;
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            font-style: italic;
            margin-top: 7px;
          }
        }
        .appdlurl {
          text-align: center;
          font-size: 16px;
          font-style: italic;
          font-weight: 600;
          color: #fff;
          border-radius: 0 0 4px 4px;
          padding: 5px 3px;
        }
      }
    }
  }
}

.left-floater {
  position: fixed;
  top: 40%;
  left: 0;
  z-index: 50;
  transform: translateY(-50%);
  width: 150px;
  height: 250px;
  background: linear-gradient(to bottom, #02142e, #015e74);
  .qr-wrapper {
    position: absolute;
    height: auto;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    padding: 0.5rem;
    background-color: #000;
    box-sizing: border-box;
    color: #fff;
    text-align: center;
    .qr-code {
      margin-bottom: 8px;
      canvas {
        display: block;
        margin: auto;
        position: relative;
        width: 100% !important;
        height: 100% !important;
        //top: 0.25rem;
      }
    }
    .text {
      font-size: 20px;
      margin: 0 0 0.25rem;
      font-style: italic;
      font-weight: bold;
    }
    .appdlurl {
      //position: absolute;
      //bottom: -20px;
      //left: 0;
      //right: 0;
      //color: #000;
      //font-weight: bold;
      //text-shadow: 0 0 4px #fff;
      //font-size: 14px;
    }
  }
}
