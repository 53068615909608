@import "./../../common/styles";
@import url("http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,600,700,300,800");
@import "util";
@import "sidebar";
/*************************
*******Typography******
**************************/

body {
  background: #fff;
  font-family: "Open Sans", sans-serif;
  color: #4e4e4e;
  line-height: 22px;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

h1,
h2,
h3 {
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #4e4e4e;
}

h1 {
  font-size: 36px;
  color: #fff;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 28px;
  color: #787878;
  font-weight: 400;
  line-height: 24px;
}

h4 {
  font-size: 16px;
}

a {
  color: #c52d2f;
  -webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition: color 300ms, background-color 300ms;
  transition: color 300ms, background-color 300ms;
  &:hover,
  &:focus {
    color: #d43133;
  }
}

hr {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #fff;
}

ul,
li {
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}

.flex {
  display: flex;
  align-items: center;
}

.btn-primary {
  padding: 8px 20px;
  background: #c52d2f;
  color: #fff;
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  &:hover,
  &:focus {
    background: #c52d2f;
    outline: none;
    box-shadow: none;
  }
}

.btn-transparent {
  border: 3px solid #fff;
  background: transparent;
  color: #fff;
  &:hover {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

a {
  &:hover,
  &:focus {
    color: #111;
    text-decoration: none;
    outline: none;
  }
}

.dropdown-menu {
  margin-top: -1px;
  min-width: 180px;
}

.center h2 {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;
}

.media > {
  .pull-left {
    margin-right: 20px;
  }
  .pull-right {
    margin-left: 20px;
  }
}

body .body-wrapper > section {
  padding: 70px 0;
}

.center {
  text-align: center;
  padding-bottom: 55px;
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

.lead {
  font-size: 16px;
  line-height: 24px;
}

.transparent-bg {
  background-color: transparent !important;
  margin-bottom: 0;
}

@-webkit-keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.shu-g {
  display: inline-block;
  width: 2px;
  height: 12px;
  background: #fff;
}

/*************************
*******Header******
**************************/

#header .search {
  display: inline-block;
}

#header nav {
  border-radius: 0;
}

.navbar > .container .navbar-brand {
  margin-left: 0;
}

.top-bar {
  padding: 10px 0;
  background: #1d2344;
  border-bottom: 1px solid #373c58;
  line-height: 28px;
}

.top-number {
  color: #fff;
  p {
    margin: 0;
  }
}

.social {
  text-align: right;
}

.social-share {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

ul.social-share li {
  display: inline-block;
  a {
    display: inline-block;
    color: #fff;
    height: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 2px;
    &:hover {
      /*background: #c52d2f;*/
      color: #c52d2f;
    }
  }
}

.navbar-brand {
  padding: 0;
  margin-top: 6px;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
  background: #1d2344;
  padding-bottom: 0;
}

.navbar-inverse {
  .navbar-nav > li > a {
    padding: 20px 23px;
    margin: 0;
    line-height: 24px;
    display: inline-block;
    border-radius: 0;
    font-size: 18px;
    color: #fff;
    &:hover {
      background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
      color: #fff;
    }
  }
  border: none;
  .navbar-brand {
    font-size: 36px;
    line-height: 50px;
    color: #fff;
  }
}

/*.navbar-inverse .navbar-nav > .active > a:focus{
  background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
  color: #fff;
}
*/

.hed .navbar-inverse .navbar-nav > {
  .active > a {
    background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
    color: #fff;
    &:hover,
    &:focus {
      background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
      color: #fff;
    }
  }
  a {
    background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
    color: #fff;
    &:hover,
    &:focus {
      background: linear-gradient(to bottom right, #dc292f 50%, #af181a 50%);
      color: #fff;
    }
  }
}

/*.navbar-inverse .navbar-nav .dropdown-menu {
  background-color: rgba(0,0,0,.85);
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.125);
  border: 0;
  padding: 0;
  margin-top: 0;
  border-top: 0;
  border-radius: 0;
  left: 0;
}
*/
/*.navbar-inverse .navbar-nav .dropdown-menu:before{
  position: absolute;
  top:0;
}

.navbar-inverse .navbar-nav .dropdown-menu > li > a {
  padding: 8px 15px;
  color: #fff;
}
*/
/*.navbar-inverse .navbar-nav .dropdown-menu > li:hover > a,
.navbar-inverse .navbar-nav .dropdown-menu > li:focus > a,
.navbar-inverse .navbar-nav .dropdown-menu > li.active > a {
  background-color: #c52d2f;
  color: #fff;
}*/

.navbar-inverse {
  .navbar-nav .dropdown-menu > li {
    &:last-child > a {
      border-radius: 0 0 3px 3px;
    }
    &.divider {
      background-color: transparent;
    }
  }
  .navbar-collapse,
  .navbar-form {
    border-top: 0;
    padding-bottom: 0;
  }
}

/*************************
*******Home Page******
**************************/

#main-slider {
  position: relative;
  padding: 0;
  /*background: #0c1127;*/
  > .carousel {
    width: 100%;
    > .carousel-inner {
      width: 100% !important;
    }
  }
}

.no-margin {
  margin: 0;
  padding: 0;
}

#main-slider {
  .carousel {
    .carousel-content {
      margin-top: 150px;
    }
    .slide-margin {
      margin-top: 140px;
    }
    h2 {
      color: #fff;
    }
    .btn-slide {
      padding: 8px 20px;
      background: #c52d2f;
      color: #fff;
      border-radius: 4px;
      margin-top: 25px;
      display: inline-block;
    }
    .slider-img {
      text-align: right;
      position: absolute;
    }
    .item {
      background-position: 50%;
      background-repeat: no-repeat;
      /*background-size: cover;*/
      left: 0 !important;
      opacity: 0;
      top: 0;
      position: absolute;
      width: 100%;
      display: block !important;
      height: 383px;
      -webkit-transition: opacity ease-in-out 500ms;
      -moz-transition: opacity ease-in-out 500ms;
      -o-transition: opacity ease-in-out 500ms;
      transition: opacity ease-in-out 500ms;
      &:first-child {
        top: auto;
        position: relative;
      }
      &.active {
        opacity: 1;
        -webkit-transition: opacity ease-in-out 500ms;
        -moz-transition: opacity ease-in-out 500ms;
        -o-transition: opacity ease-in-out 500ms;
        transition: opacity ease-in-out 500ms;
        z-index: 1;
      }
    }
  }
  .prev,
  .next {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    /*
background-color: #c52d2f;*/
    color: #fff;
    margin-top: -25px;
    height: 40px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 4px;
    z-index: 5;
  }
  .prev:hover,
  .next:hover {
    background-color: #000;
  }
  .prev {
    left: 10px;
  }
  .next {
    right: 10px;
  }
  .carousel-indicators {
    width: 48%;

    li {
      width: 20px;
      height: 20px;
      background-color: transparent;
      margin: 0 15px 0 0;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 38px;
        height: 20px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0);
        left: -3px;
        top: -3px;
      }
    }

    .active {
      width: 38px;
      height: 20px;
      background-color: #c52d2f;
      margin: 0 15px 0 0;
      /*border: 1px solid #c52d2f;*/
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 38px;
        height: 20px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0);
        /*border: 1px solid #FFF;*/
        left: -3px;
        top: -3px;
      }
    }
  }
  .active .animation {
    &.animated-item-1 {
      -webkit-animation: fadeInUp 300ms linear 300ms both;
      -moz-animation: fadeInUp 300ms linear 300ms both;
      -o-animation: fadeInUp 300ms linear 300ms both;
      -ms-animation: fadeInUp 300ms linear 300ms both;
      animation: fadeInUp 300ms linear 300ms both;
    }
    &.animated-item-2 {
      -webkit-animation: fadeInUp 300ms linear 600ms both;
      -moz-animation: fadeInUp 300ms linear 600ms both;
      -o-animation: fadeInUp 300ms linear 600ms both;
      -ms-animation: fadeInUp 300ms linear 600ms both;
      animation: fadeInUp 300ms linear 600ms both;
    }
    &.animated-item-3 {
      -webkit-animation: fadeInUp 300ms linear 900ms both;
      -moz-animation: fadeInUp 300ms linear 900ms both;
      -o-animation: fadeInUp 300ms linear 900ms both;
      -ms-animation: fadeInUp 300ms linear 900ms both;
      animation: fadeInUp 300ms linear 900ms both;
    }
    &.animated-item-4 {
      -webkit-animation: fadeInUp 300ms linear 1200ms both;
      -moz-animation: fadeInUp 300ms linear 1200ms both;
      -o-animation: fadeInUp 300ms linear 1200ms both;
      -ms-animation: fadeInUp 300ms linear 1200ms both;
      animation: fadeInUp 300ms linear 1200ms both;
    }
  }
}

/*##############################################################*/

#main-sliders {
  position: relative;
  /*background: #0c1127;*/
}

.no-margin {
  margin: 0;
  padding: 0;
}

#main-sliders {
  .carousel-content {
    margin-top: 150px;
  }
  .slide-margin {
    margin-top: 140px;
  }
  h2 {
    color: #fff;
  }
  .btn-slide {
    padding: 8px 20px;
    background: #c52d2f;
    color: #fff;
    border-radius: 4px;
    margin-top: 25px;
    display: inline-block;
  }
  .slider-img {
    text-align: right;
    position: absolute;
  }
  .sd {
    background-image: url(../img/cai1.png);
    height: 135px;
    width: 240px;
    margin: 20px;
    border-radius: 10px;
    background-position: 50%;
    background-repeat: no-repeat;
    box-shadow: #ccc 0px 0px 5px;
  }
  .item {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0 !important;
    opacity: 0;
    top: 0;
    position: absolute;
    width: 100%;
    display: block !important;
    -webkit-transition: opacity ease-in-out 500ms;
    -moz-transition: opacity ease-in-out 500ms;
    -o-transition: opacity ease-in-out 500ms;
    transition: opacity ease-in-out 500ms;
    &:first-child {
      top: auto;
      position: relative;
    }
    &.active {
      opacity: 1;
      -webkit-transition: opacity ease-in-out 500ms;
      -moz-transition: opacity ease-in-out 500ms;
      -o-transition: opacity ease-in-out 500ms;
      transition: opacity ease-in-out 500ms;
      z-index: 1;
    }
  }
  .prev,
  .next {
    position: absolute;
    top: 50%;
    background-color: #c52d2f;
    color: #fff;
    margin-top: -25px;
    height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 4px;
    z-index: 5;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .prev:hover,
  .next:hover {
    background-color: #ccc;
  }
  .prev {
    left: 10px;
  }
  .next {
    right: 10px;
  }
  .carousel-indicators {
    li {
      width: 20px;
      height: 20px;
      background-color: #fff;
      margin: 0 15px 0 0;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: rgba(0, 0, 0, 0);
        border: 1px solid #fff;
        left: -3px;
        top: -3px;
      }
    }

    .active {
      width: 20px;
      height: 20px;
      background-color: #c52d2f;
      margin: 0 15px 0 0;
      border: 1px solid #c52d2f;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background: #c52d2f;
        border: 1px solid #c52d2f;
        left: -3px;
        top: -3px;
      }
    }
  }
  .active .animation {
    &.animated-item-1 {
      -webkit-animation: fadeInUp 300ms linear 300ms both;
      -moz-animation: fadeInUp 300ms linear 300ms both;
      -o-animation: fadeInUp 300ms linear 300ms both;
      -ms-animation: fadeInUp 300ms linear 300ms both;
      animation: fadeInUp 300ms linear 300ms both;
    }
    &.animated-item-2 {
      -webkit-animation: fadeInUp 300ms linear 600ms both;
      -moz-animation: fadeInUp 300ms linear 600ms both;
      -o-animation: fadeInUp 300ms linear 600ms both;
      -ms-animation: fadeInUp 300ms linear 600ms both;
      animation: fadeInUp 300ms linear 600ms both;
    }
    &.animated-item-3 {
      -webkit-animation: fadeInUp 300ms linear 900ms both;
      -moz-animation: fadeInUp 300ms linear 900ms both;
      -o-animation: fadeInUp 300ms linear 900ms both;
      -ms-animation: fadeInUp 300ms linear 900ms both;
      animation: fadeInUp 300ms linear 900ms both;
    }
    &.animated-item-4 {
      -webkit-animation: fadeInUp 300ms linear 1200ms both;
      -moz-animation: fadeInUp 300ms linear 1200ms both;
      -o-animation: fadeInUp 300ms linear 1200ms both;
      -ms-animation: fadeInUp 300ms linear 1200ms both;
      animation: fadeInUp 300ms linear 1200ms both;
    }
  }
}

/*$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/

#services {
  background: #000 url(../../img/fwbanner.png);
  background-size: cover;
}

.services-wrap {
  padding: 44px 0px;
  background: #fff;
  border-radius: 4px;
  h3 {
    font-size: 20px;
    margin: 10px 0;
    font-weight: bold;
  }
  .pull-left {
    margin-right: 20px;
  }
}

#about-us {
  background: #000 url(../../img/gywmbanner.png);
  background-size: cover;
  .bgcolor {
    background: #79809e;
    color: #fff;
    &:hover {
      background: #dc292f;
    }
  }
  h3 {
    color: #fff;
    letter-spacing: 2px;
  }
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}

#about-us .lead {
  color: #fff;
  letter-spacing: 4px;
}

.text-font {
  height: 200px;
  padding: 0 40px 70px;
}

.text-fonts {
  padding: 26px 40px 20px;
}

#support-services {
  h3 {
    color: #fff;
  }
  .lead {
    color: #fff;
    letter-spacing: 4px;
  }
}

#game .lead {
  color: #ccc;
  letter-spacing: 4px;
  margin-bottom: -19px;
}

/***********************
****Service page css****
***********************/

.services {
  padding: 0;
}

.get-started {
  background: none repeat scroll 0 0 #f3f3f3;
  border-radius: 3px;
  padding-bottom: 30px;
  position: relative;
  margin-bottom: 18px;
  margin-top: 60px;
  h2 {
    padding-top: 30px;
    margin-bottom: 20px;
  }
}

.request {
  bottom: -15px;
  left: 50%;
  position: absolute;
  margin-left: -110px;
  h4 {
    position: absolute;
    width: 220px;
    position: relative;
    a {
      background: #c52d2f;
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      border-radius: 3px;
      padding: 5px 15px;
    }
    &:after {
      border-color: #c52d2f transparent transparent;
      border-style: solid;
      border-width: 12px;
      bottom: -18px;
      content: "";
      height: 0;
      position: absolute;
      right: 13px;
      z-index: -99999;
    }
    &:before {
      border-color: #c52d2f transparent transparent;
      border-style: solid;
      border-width: 12px;
      bottom: -18px;
      content: "";
      height: 0;
      left: 13px;
      position: absolute;
      z-index: -99999;
    }
  }
}

.clients-area {
  padding: 60px;
}

.clients-comments {
  p {
    font-size: 20px;
    font-family: "微软雅黑";
    line-height: 26px;
    margin: 35px;
  }
  i {
    color: red;
    font-size: 24px;
  }
  img {
  }
  h4 {
    font-weight: 300;
    margin-top: 15px;
    span {
      font-weight: 700;
      font-style: oblique;
    }
  }
}

.footer {
  background: none repeat scroll 0 0 #2e2e2e;
  border-top: 5px solid #c52d2f;
  height: 84px;
  margin-top: 110px;
}

.footer_left {
  padding: 10px;
}

.text-left {
  color: #ffffff;
  font-size: 12px;
  margin-top: 15px;
}

/*********************
**********************
*****************
********************game*
***********************
*********************/

#game {
  .game-le img {
    padding: 0;
  }
  .media-body {
    margin-top: 36px;
  }
  button {
    border-radius: 20px;
  }
}

/***********************
********* Footer ******
************************/

#bottom {
  background: #f5f5f5;
  border-bottom: 5px solid #c52d2f;
  font-size: 14px;
  h3 {
    margin-top: 0;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 22px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: block;
      padding: 5px 0;
      a {
        color: #808080;
        &:hover {
          color: #c52d2f;
        }
      }
    }
  }
  .widget {
    margin-bottom: 0;
  }
}

#footer {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
  background: #b52a2b;
  a {
    color: #fff;
    &:hover {
      color: #c52d2f;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    > li {
      display: inline-block;
      margin-left: 15px;
    }
  }
}

/*****************
about-us**********
******************/

/*support-services*/

#support-services {
  background: #1d2344;
}

/*banner 上登录*/

.fatt {
  position: absolute;
  z-index: 20;
  top: 7%;
  left: 67%;
}

.user_icon {
  position: relative;
  width: 200px;
  z-index: 40;
  margin-bottom: 10px;
  color: #26366e;
  i {
    position: absolute;
    z-index: 55;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    //padding: 12px 5px;
  }
}

.login_txtbx {
  width: 200px;
  height: 34px;
  padding-left: 22px;
  background: #0f111d;
  border: 1px solid #2f4280;
  caret-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
}

.login_txtbxs {
  width: 110px;
  height: 34px;
  padding-left: 22px;
  background: #0f111d;
  border: 1px solid #2f4280;
  caret-color: #fff;
  color: #fff;
}

.pwd_icon {
  position: relative;
  width: 200px;
  z-index: 40;
  color: #26366e;
  margin-bottom: 10px;
  i {
    position: absolute;
    left: 0;
    z-index: 55;
    padding: 12px 5px;
  }
}

.val_icon {
  display: inline-flex;
  position: relative;
  width: 215px;
  z-index: 40;
  color: #26366e;
  margin-bottom: 20px;
  i {
    position: absolute;
    left: 0;
    z-index: 55;
    padding: 12px 5px;
  }
  canvas {
    width: 70px;
    height: 38px;
    margin-left: 16px;
    padding-left: 8px;
    background: #c1d2e0;
  }
}

.land {
  width: 100px;
  border-radius: 20px;
  padding: 3px 12px;
}

/*container*/

/*Open account 页面*/

.login {
  background: #fff;
  height: 100%;
  padding: 10px 20px;
  height: 700px;
  form {
    width: 380px;
    margin: auto;
    padding: 10px 0;
  }
}

.logn_in {
  display: inline-flex;
  position: relative;
  /*justify-content: space-between;*/
  align-items: center;
  z-index: 40;
  width: 380px;
  height: 45px;
  padding-left: 22px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #fafafa;
}

.logn_ins {
  display: inline-flex;
  position: relative;
  /*justify-content: space-between;*/
  align-items: center;
  z-index: 40;
  width: 266px;
  height: 45px;
  padding-left: 22px;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  background: #fafafa;
  & + .J_codeimg {
    height: 45px;
    margin-bottom: 2px;
  }
}

.logn_in i {
  position: absolute;
  left: 0;
  z-index: 55;
  padding: 14px 6px;
}

.J_codeimg {
  width: 80px;
  margin-left: 10px;
  padding-left: 8px;
  background: #ccc;
}

.logn_in input,
.logn_ins input {
  width: 100%;
  padding-left: 22px;
  border: none;
  background: #fafafa;
}

/*.logn_in .required{
    width: 380px;
    height: 45px;
    padding-left: 22px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
}*/

.logn_in .requireds {
  /*  width: 270px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;*/
}

.leads {
  color: #ccc;
  font-size: 14px;
  letter-spacing: 4px;
  margin-bottom: -19px;
  font-weight: 100;
}

.botn {
  width: 380px;
  height: 46px;
  font-size: 18px;
  margin-top: 20px;
}

/*deposit-withdrawal*/

.text_wen {
  font-size: 18px;
  color: black;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  font-weight: bold;
}

#scrollDiv {
  position: absolute;
  width: 55%;
  height: 36px;
  min-height: 20px;
  line-height: 24px;
  border: #ccc 1px solid;
  overflow: hidden;
  background: #fff;
  left: 22%;
  top: 97%;
  box-shadow: #666 0px 0px 10px;
  z-index: 70;
  display: flex;
  align-items: center;
  color: red;
  font-size: 16px;
  .marquee-title {
    margin: 0 12px 0 0;
  }
  > .marquee-wrapper {
    width: calc(100% - 120px);
    color: #000;
  }
}

.scrollDiv_lis {
}

#scrollDiv li {
  width: 100%;
  height: 20px;
  padding: 20px;
  margin-left: 30px;
  color: black;
}

.title {
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: -webkit-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 7) 100%);
  background-image: -moz-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
  background-image: -o-linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
  background-image: linear-gradient(right, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
}

.titles {
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
  background-image: -moz-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
  background-image: -o-linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
  background-image: linear-gradient(left, rgba(12, 17, 39, 0) 0%, rgba(12, 17, 39, 1) 100%);
}

.small_lun {
  width: 100%;
  left: 30%;
  background: rgba(95, 95, 95, 0.5);
  height: 45px;
  line-height: 47px;
  bottom: -10px;
}

#content {
  background: url(../img/bg.png);
  background-size: cover;
  /*background: no-repeat;*/
}
a {
  color: black;
}
.sub-content-nav .nav > li > a {
  padding: 10px 5px;
  box-sizing: border-box;
  background: transparent !important;
}
.nav-tabs > li > a {
  font-size: 16px;
}
.nav-tabs > li > a:hover {
  color: red;
  border: none;
  background: transparent;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  cursor: default;
  border: none;
  color: red;
  border-bottom: 3px solid red;
}

.rights {
  background: #fff;
  //height: 100%;
  padding: 10px 20px;
}
#content .media-heading {
  font-size: 18px;
}
#content .media-cont {
  color: #ccc;
}
#content .pull-right {
  margin-top: -41px;
  color: #ccc;
}
#content .media-body {
  margin-top: 10px;
}
#content .services-coj {
  padding: 20px;
  margin-bottom: 10px;
}

#content .tab-content {
  margin-top: 10px;
}
.panel-group .panel {
  border: none;
}
.panel-default > .panel-heading {
  background: transparent;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-bottom: 2px dashed #ccc;
  border-top: none;
}

.rule-content {
  ul,
  ol {
    padding-left: 2rem;
  }
  li {
    line-height: 2rem;
  }
  .tab-pane a {
    color: #1a2c8e;
  }
  .red {
    color: red;
  }
}

.promo-content {
  h1 {
    color: initial;
  }
  .item {
    margin-bottom: 20px;
  }
  .item-head {
    width: 100%;
  }
  .item-content {
    display: none;
    background-color: #fff;
    box-sizing: border-box;
  }
}

.app-download-popup {
  display: flex;
  //align-items: center;
  justify-content: center;
  width: 800px !important;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 24px;
  box-sizing: border-box;
  margin: auto;
  .qr-code {
    position: absolute;
    top: 105px;
    right: 49px;
  }
}

body .swipe-captcha__overlay {
  z-index: 99;
}
